<template>
  <div class="companyVideo">
    <video
      class="edu-viedo"
      width="100%"
      height="600px"
      controls="controls"
      style="object-fit: cover"
      :src="video"
    ></video>
      <!-- autoplay -->
  </div>
</template>

<script>
import axios from "axios"; //引入axios
import { mapState } from 'vuex'
export default {
  name: "",
  data() {
    return {
      video: "",
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("https://www.claybbt.com/queryTitleId", {
          params: {
            language: lang,
              titleId: this.nodeId[this.$route.query.titleId] || this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.video = res.data.data.img;
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
  computed: {
    ...mapState(['nodeId']),
  },
  filters: {},
};
</script>

<style lang="less" scoped>
.companyVideo {
  width: 100%;
  background: #fafafa;
  padding: 50px 50px 10px;
  box-sizing: border-box;
  .cv-video {
    width: 200px;
    height: 300px;
  }
}
</style>
